<template>
  <div class="form-wrapper">
    <h3
      class="font-weight-bolder mb-1"
      style="color: #646464"
    >
      {{ $t(STOCK_ITEMS_TITLE) }}
    </h3>
    <l-table-list-collector
      module-name="orders"
      :table-columns="StockItemTableColumns"
      :is-searchable="false"
      :fetched-data="quote.products"
      class="rfq-stock-items"
      :has-footer="false"
      table-extra-classes="rfq-stock-items"
    >
      <template #cell(action)="{ data: { item: { id } } }">
        <feather-icon
          v-if="!isPrint"
          icon="LEyeIcon"
          size="24"
          class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
          @click="gotoNewPage({name: 'home-catalog-general-info', params: { id } }, $event, true)"
        />
        <span>{{ }}</span>
      </template>
    </l-table-list-collector>
  </div>
</template>

<script>
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'StockItems',
  components: {
    LTableListCollector,
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      required: true,
    },
  },
  // computed: {
  //   quote() {
  //     return this.$store.state[this.MODULE_NAME].quote
  //   },
  // },
  setup() {
    const { STOCK_ITEMS_TITLE, StockItemTableColumns } = config()
    const { MODULE_NAME } = mainConfig()

    return {
      MODULE_NAME,
      STOCK_ITEMS_TITLE,
      StockItemTableColumns,
    }
  },
}
</script>
<style lang="scss">
.rfq-stock-items {
  table td {
    padding: 5px 8px !important
  }

  thead th {
    padding: 5px 8px !important
  }

   tbody tr:last-child td {
    border-bottom: solid 1px #dde3e7 !important;

     &:first-child {
       border-bottom-left-radius: 5px !important;
     }
     &:last-child {
       border-bottom-right-radius: 5px !important;
     }
   }
}
</style>
