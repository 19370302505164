<template>
  <div>
    <div>
      <b-row>
        <b-col
          :cols="`${isPrint ? '12' : '6'}`"
        >
          <div
            v-for="{ key , label } in orderInformationObjectForRenderBottom"
            :key="key"
          >
            <div class="d-flex flex-column form-wrapper">
              <h4
                class="font-weight-bolder"
                style="color: #646464"
              >
                {{ label }}
              </h4>
              <span>{{ getValueFromGivenObjectByKey(quote, key) }}</span>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="!isPrint"
          cols="6"
        >
          <attachment :attachments="getValueFromGivenObjectByKey(quote, 'attachments')" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/no-named-as-default
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from './config'
import mainConfig from '../../../config'
import Attachment from './components/Attachment.vue'

export default {
  name: 'OrderInformationBottom',
  components: {
    BRow,
    BCol,
    Attachment,
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      required: true,
    },
  },
  // computed: {
  //   quote() {
  //     return this.$store.state[this.MODULE_NAME].quote
  //   },
  // },
  setup() {
    const { orderInformationObjectForRenderBottom } = config()
    const { MODULE_NAME } = mainConfig()

    return {
      MODULE_NAME,
      getValueFromGivenObjectByKey,
      orderInformationObjectForRenderBottom,
    }
  },
}
</script>
