<template>
  <div id="form-header">
    <order-information-view
      class="mb-1"
      :quote="{
        ...order,
        supplier: order.customer,
        dispatch_contact: order.dispatch_contact && {...order.dispatch_contact,firstname: order.dispatch_contact.first_name,lastname: order.dispatch_contact.last_name,phone: order.dispatch_contact.phone_one},
        billing_address: order.return_warehouse
      }"
    />
    <stock-items-view
      class="mb-1"
      :quote="order"
    />
    <order-information-bottom :quote="{...order,external_order_notes: order.external_order_reference}" />
    <form-bottom
      :form-ref="$refs"
      :buttons-list="readyDraftButtons"
    />
  </div>
</template>

<script>
import OrderInformationBottom
from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/OrderInformationBottom.vue'
import OrderInformationView
from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/OrderInformation.vue'
import StockItemsView from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/StockItems.vue'
import FormBottom from '../../components/form-submit-buttons/FormBottom.vue'
import config from '../../config'
import formBtnConfig from '../../components/form-submit-buttons/libs'

export default {
  name: 'CommonForm',
  components: {
    OrderInformationBottom,
    OrderInformationView,
    StockItemsView,
    FormBottom,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  created() {
  },
  methods: {

  },
  setup() {
    const { MODULE_NAME } = config()
    const {
      readyDraftButtons,
    } = formBtnConfig()

    return {
      MODULE_NAME,
      readyDraftButtons,
    }
  },
}
</script>
